// MyModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const MyModal = ({ show, handleClose , image1 , image2 ,image3}) => {
  
  return (
    <Modal show={show} onHide={handleClose} centered>
      {/* Add the 'centered' prop to the Modal component */}
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal Title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
      <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active d-none" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2" className='d-none'></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3" className='d-none'></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active" data-bs-interval="2000">
        <img  src={`img/${image1}`} className="d-block w-100" alt="Saeed Bin Hameed"/>
        <div className="carousel-caption d-none d-md-block">
          {/* <h5>First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p> */}
        </div>
      </div>
      <div className="carousel-item" data-bs-interval="2000">
        <img src={`img/${image2}`} className="d-block w-100" alt="Saeed Bin Hameed"/>
        <div className="carousel-caption d-none d-md-block">
          {/* <h5>Second slide label</h5>
          <p>Some representative placeholder content for the second slide.</p> */}
        </div>
      </div>
      <div className="carousel-item" data-bs-interval="2000">
        <img  src={`img/${image3}`} className="d-block w-100" alt="Saeed Bin Hameed"/>
        <div className="carousel-caption d-none d-md-block">
          {/* <h5>Third slide label</h5>
          <p>Some representative placeholder content for the third slide.</p> */}
        </div>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;




