import React from "react";

const CompanyMain = () => {
  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div
            className="col-lg-12 about-text py-5 wow fadeIn"
            data-wow-delay="0.5s"
          >
            <div className="p-lg-5 pe-lg-0">
              <div className="section-title text-start">
                <h1 className="display-5 mb-4">Company Profile</h1>
              </div>
              <p className="mb-4 pb-2">
                <h5>
                  <u>SAEED BIN HAMED AL BELADI CO.</u> for finishing work
                </h5>{" "}
                Performs Fibrous Gypsum Decorative Pannel, Gypsum Board,
                Acoustic Ceiling Tile, Paint Flooring, Wood Work and maintains
                all workshops, tools, vehicles, equipment and personnel
                necessary to support these projects. The following is a summary
                of the organizational performance in support of these services:
              </p>
              <p className="mb-4 pb-2">
                {" "}
                <strong>
                  {" "}
                  <u>The courtyards and shops </u>
                </strong>
                are maintained by{" "}
                <strong>
                  <u>SAEED BIN HAMED AL BELADI CO,</u>
                </strong>{" "}
                The office location is located in Khaled bin Waleed Street and
                in the Advance Business Center Building, Al Sharafiyah district,
                comprising an area of 105 square meters. An additional area of
                500 square meters in Hamadaniyah dist., Jeddah, is used as a
                labor camp and used for the storage of primary finishing
                materials.
              </p>

              <p className="mb-4 pb-2">
                Maintenance parts and supplies are purchased locally on an
                ongoing basis and stored for future use as needed.
              </p>
              <h5>
                <u>
                  Vehicles of more than 6 units are currently owned and operated
                  by SAEED BIN HAMED AL BELADI CO. Vehicles are assigned to
                  workplaces based on job requirements.
                </u>
              </h5>
              <p>
                This fleet consists mainly of pick-ups, vans, buses and sedans.
                <strong>
                  The fleet of SAEED BIN HAMED AL BELADI CO. work vehicles for
                  the finishing
                </strong>{" "}
                is valued at US$62,000.
              </p>
              <p>
                <strong>
                  <u>Tools and equipment</u>
                </strong>{" "}
                are provided by SAEED BIN HAMED AL BELADI CO. to its workers in
                the quantity required to sustain the work effort. The technical
                staff has tools according to the requirements of their
                profession.
              </p>
              <p>
                <strong>
                  <u>The supervisory staff</u>
                </strong>{" "}
                currently consists of more than 5 people and is responsible for
                overseeing the various aspects of the work effort in their
                particular area of work. Most of the management staff of the
                company, SAEED BIN HAMED AL BELADI CO, each have more than
                fifteen (15) years of cumulative experience working in the past
                at present on interior design projects with similar companies.
              </p>

              <p>
              <strong><u>Technician and skilled workers</u></strong> currently numbering over thirty-one (31), which includes technicians and skilled workers experienced in finishing work. Many of our skilled workers are from India, Pakistan and Africans.
              </p>
              <p><strong><u>The camp facilities</u></strong> are maintained by SAEED BIN HAMED AL BELADI CO.  Hamadaniyah, Jeddah. In total, the camp facility is capable of accommodating more than sixty-five (65) workers. The SAEED BIN HAMED AL BELADI camps are complete with toilet block, dining room, kitchen and other necessary support facilities, supervisory staff receive reasonable housing allowances.</p>

              <p><strong><u>The performance</u></strong> at work is marked by SAEED BIN HAMED AL BELAD's ability to perform a large amount of work at the highest level, often on short notice and in remote locations. </p>

              <p>The extensive experience gained by SAEED BIN HAMED AL BELADI CO. in the field of finishing work results in the following:
                <ul>
                  <li>Skilled workers and supervisors.</li>
                  <li>Excellent fully equipped shop facilities</li>
                  <li>Suitable vehicles in sufficient numbers and in good working order</li>
                  <li>Well stocked warehouse to provide the necessary tools and materials for the work.</li>
                  <li>On-site camp facilities in the appropriate quarter and dining hall facilities to meet the needs of company personnel.</li>
                </ul>
              </p>

              <p>The following is a summary of the approximate investment of SAEED BIN HAMED AL BELADI CO. of the year 2023 in the equipment and facilities needed to support the work effort:</p>
              <table border={"2px"}>
                <tr style={{borderBottom:"2px solid black"}}>
                <th style={{borderRight:"2px solid black"}}>DESCRIPTION</th>
                <th>VALUE AT COST</th>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Building (Office & Documentation fees)</td>
                  <td>$US &ensp; 97,671.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Building (Camp)</td>
                  <td>$US &ensp; 33,322.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Tools & Equipment </td>
                  <td>$US &ensp; 47,428.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Office and Camp Furnishing</td>
                  <td>$US &ensp; 30,102.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Office and Camp </td>
                  <td>$US &ensp; 12,406.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Air-Conditioners</td>
                  <td>$US &ensp; 7,610.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Vehicles</td>
                  <td>$US &ensp; 61,225.00</td>
                </tr>
                <tr>
                  <td style={{borderRight:"2px solid black"}}>Stored Materials</td>
                  <td>$US &ensp; 24,808.00</td>
                </tr>
                <tr style={{fontWeight:"bold",borderTop:"2px solid black"}}>
                  <td style={{borderRight:"2px solid black"}}>TOTAL</td>
                  <td>$US &ensp; 314,572.00</td>
                </tr>
              </table>
              <p>The significant investment made in these supporting facilities and equipment indicates further that SAEED BIN HAMED AL BELADI’s unrelenting interest in doing efficient work, excellent workmanship and its interest in its staff are the indispensable factor in pursuing any project.</p>
              <img
                src="img/COVER LATTER_page-0001.jpg"
                height={"800px"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMain;
